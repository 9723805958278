import * as React from 'react';
import "../app.css";
import image1 from "../IrinaRybalova.png";
import image2 from "../BlueEyes.png";
import image3 from "../Bubbles.png";
//const image4 = "https://startbootstrap.github.io/startbootstrap-freelancer/assets/img/portfolio/game.png";
//const image5 = "https://startbootstrap.github.io/startbootstrap-freelancer/assets/img/portfolio/safe.png";
//const image6 = "https://startbootstrap.github.io/startbootstrap-freelancer/assets/img/portfolio/submarine.png";

export default function Websites() {

    return (
        <section class="page-section portfolio" id="portfolio">
            <div class="container">
                <h2 class="page-section-heading text-center text-uppercase text-secondary mb-0">My Websites</h2>
                <div class="divider-custom">
                    <div class="divider-custom-line"></div>
                    <div class="divider-custom-icon"><i class="fas fa-star"></i></div>
                    <div class="divider-custom-line"></div>
                </div>
                <div class="row justify-content-center">
                    <a class="col-md-6 col-lg-4 mb-5" rel="noreferrer" href="https://blueeyesrestaurant.com/menu-2023/" target="_blank">
                        <div class="portfolio-item mx-auto">
                            <div class="portfolio-item-caption d-flex align-items-center justify-content-center h-100 w-100">
                                <div class="portfolio-item-caption-content text-center text-white"><i class="fas fa-plus fa-3x"></i></div>
                            </div>
                            <img style={{width: 360, height: 200}} src={image2} alt="..." />
                        </div>
                    </a>
                    <a class="col-md-6 col-lg-4 mb-5" rel="noreferrer" href="https://bubbles-3-laundromat.business.site/" target="_blank">
                        <div class="portfolio-item mx-auto">
                            <div class="portfolio-item-caption d-flex align-items-center justify-content-center h-100 w-100">
                                <div class="portfolio-item-caption-content text-center text-white"><i class="fas fa-plus fa-3x"></i></div>
                            </div>
                            <img style={{width: 360, height: 200}} src={image3} alt="..." />
                        </div>
                    </a>
                    <a class="col-md-6 col-lg-4 mb-5" rel="noreferrer" href="https://irinarybalovadds.com" target="_blank">
                        <div class="portfolio-item mx-auto">
                            <div class="portfolio-item-caption d-flex align-items-center justify-content-center h-100 w-100">
                                <div class="portfolio-item-caption-content text-center text-white"><i class="fas fa-plus fa-3x"></i></div>
                            </div>
                            <img style={{width: 360, height: 200}} src={image1} alt="..." />
                        </div>
                    </a>
                </div>
            </div>
        </section>
    );
}
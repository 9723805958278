import * as React from 'react';
import icon from "../icon.png";
export default function Navbar() {

    return (
        <nav class="navbar navbar-expand-lg bg-secondary text-uppercase fixed-top" id="mainNav">
            <div class="container">
                <a href="/">
                    <img style={{maxHeight: 50, marginLeft: 20}} src={icon} alt="..."/>
                    <a style={{fontSize: 16}} class="navbar-brand">Edward's Web Solutions</a>    
                </a>
                <button
                    style={{marginRight: 10}}
                    class="navbar-toggler text-uppercase font-weight-bold bg-primary text-white rounded"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarNav"
                    aria-controls="navbarNav"
                    aria-expanded="false"
                    aria-label="Toggle navigation">
                    <i class="fas fa-bars"></i>
                </button>
                <div class="collapse navbar-collapse" id="navbarNav">
                    <ul class="navbar-nav ms-auto">
                        <li class="nav-item mx-0 mx-lg-1">
                            <a style={{marginLeft: 20}} class="nav-link py-3 px-0 px-lg-3 rounded" href="/">Home</a>
                        </li>
                        <li class="nav-item mx-0 mx-lg-1">
                            <a style={{marginLeft: 20}} class="nav-link py-3 px-0 px-lg-3 rounded" href="/faq">FAQ</a>
                        </li>
                        <li class="nav-item mx-0 mx-lg-1">
                            <a style={{marginLeft: 20}} class="nav-link py-3 px-0 px-lg-3 rounded" href="/pricing">Pricing</a>
                        </li>
                        <li class="nav-item mx-0 mx-lg-1">
                            <a style={{marginLeft: 20}} class="nav-link py-3 px-0 px-lg-3 rounded" href="/contact">Contact</a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
}
import * as React from 'react';

export default function About() {

    return (
        <section class="page-section bg-primary text-white mb-0" id="about">
            <div class="container">
                <h2 class="page-section-heading text-center text-uppercase text-white">About</h2>
                <div class="divider-custom divider-light">
                    <div class="divider-custom-line"></div>
                    <div class="divider-custom-icon"><i class="fas fa-star"></i></div>
                    <div class="divider-custom-line"></div>
                </div>
                <div class="row">
                    <div class="col-lg-4 ms-auto">
                        <p class="lead" style={{marginLeft: 10, marginRight: 10}}>
                            Welcome to our website! We specialize in creating customized websites for businesses, helping them establish a strong online presence. With a focus on user-friendly design and effective communication, we craft websites that showcase your brand identity and drive conversions.
                        </p></div>
                    <div class="col-lg-4 me-auto">
                        <p class="lead" style={{marginLeft: 10, marginRight: 10}}>
                            A professionally designed website serves as your digital storefront, available 24/7 to attract potential customers and provide them with essential information about your products or services. Let us help you create a captivating website that elevates your business in the digital world. Contact us today to discuss your requirements and embark on a journey to online success.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
}
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
//Pages
import Home from "./Pages/Home";
import Pricing from "./Pages/Pricing";
import Contact from "./Pages/Contact";
import Faqs from "./Pages/FAQ";
//Components
import Navbar from "./Components/Navbar";
import { CssBaseline } from "@mui/material";
import Footer from "./Components/Footer";

function App() {
  return (
    <Router>
      <CssBaseline />
      <Navbar />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/pricing" component={Pricing} />
        <Route exact path="/contact" component={Contact} />
        <Route exact path="/faq" component={Faqs} />
      </Switch>
      <Footer />
    </Router >
  );
}

export default App;

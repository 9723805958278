import { Container } from '@mui/material';
import * as React from 'react';
import { Helmet } from "react-helmet-async";

export default function Pricing() {
    return (
        <>
            <Helmet>
                <title>Edward's Web Solutions Pricing</title>
                <meta name="description" content="Edward\'s Web Solutions is here to help you take your business to the next level. 
                    We specialize in creating websites for businesses to increase the amount of customers you get." />
                <link rel="canonical" href="/pricing" />
            </Helmet>
            <Container style={{ minHeight: "91vh", display: 'flex', flexDirection: "column", marginTop: 120 }}>
                <br />
                <h2 style={{ textAlign: "center" }}>Plans</h2>
                <br />
                <div class="text-center">
                    <div class="container">
                        <div class="row pt-4">
                            <div class="col-md-6">
                                <div class="card mb-4 box-shadow">
                                    <div class="card-header">
                                        <h4 class="my-0 font-weight-normal">Basic</h4>
                                    </div>
                                    <div class="card-body">
                                        <h1><b><b>$114.99</b> </b><small class="text-muted">/ mo</small></h1>
                                        <ul class="list-unstyled mt-3 mb-4">
                                            <li>
                                                <i class="fa-solid fa-xmark"></i>
                                                &nbsp;Setup Cost
                                            </li>
                                            <li>
                                                <i class="fa-solid fa-check" />
                                                &nbsp;24/7 Maintenance
                                            </li>
                                            <li>
                                                <i class="fa-solid fa-check" />
                                                &nbsp;Custom Domain
                                            </li>
                                            <li>
                                                <i class="fa-solid fa-check" />
                                                &nbsp;Email Contact Form
                                            </li>
                                            <li>
                                                <i class="fa-solid fa-check" />
                                                &nbsp;Unlimited Bandwidth
                                            </li>
                                            <li>
                                                <i class="fa-solid fa-check" />
                                                &nbsp;200 GB Storage
                                            </li>
                                            <li>
                                                <i class="fa-solid fa-check" />
                                                &nbsp;Mobile Optimized Website
                                            </li>
                                            <li>
                                                <i class="fa-solid fa-check" />
                                                &nbsp;Automatic Backup
                                            </li>
                                            <li>
                                                <i class="fa-solid fa-check" />
                                                &nbsp;Cancel at Any Time
                                            </li>
                                            <li>
                                                <i class="fa-solid fa-xmark"></i>
                                                &nbsp;Search Engine Optimization
                                            </li>
                                            <li>
                                                <i class="fa-solid fa-xmark"></i>
                                                &nbsp;Advanced Website Design
                                            </li>
                                        </ul>
                                        <a style={{ textDecoration: "none" }} href="/contact">
                                            <button type="button" class="btn btn-lg btn-block btn-outline-primary">Contact Us</button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="card mb-4 box-shadow">
                                    <div class="card-header">
                                        <h4 class="my-0 font-weight-normal">Professional</h4>
                                    </div>
                                    <div class="card-body">
                                        <h1><b><b>$199.99</b> </b><small class="text-muted">/ mo</small></h1>
                                        <ul class="list-unstyled mt-3 mb-4">
                                            <li>
                                                <i class="fa-solid fa-xmark"></i>
                                                &nbsp;Setup Cost
                                            </li>
                                            <li>
                                                <i class="fa-solid fa-check" />
                                                &nbsp;24/7 Maintenance
                                            </li>
                                            <li>
                                                <i class="fa-solid fa-check" />
                                                &nbsp;Custom Domain
                                            </li>
                                            <li>
                                                <i class="fa-solid fa-check" />
                                                &nbsp;Email Contact Form
                                            </li>
                                            <li>
                                                <i class="fa-solid fa-check" />
                                                &nbsp;Unlimited Bandwidth
                                            </li>
                                            <li>
                                                <i class="fa-solid fa-check" />
                                                &nbsp;Unlimited Storage
                                            </li>
                                            <li>
                                                <i class="fa-solid fa-check" />
                                                &nbsp;Mobile Optimized Website
                                            </li>
                                            <li>
                                                <i class="fa-solid fa-check" />
                                                &nbsp;Automatic Backup
                                            </li>
                                            <li>
                                                <i class="fa-solid fa-check" />
                                                &nbsp;Cancel at Any Time
                                            </li>
                                            <li>
                                                <i class="fa-solid fa-check" />
                                                &nbsp;Search Engine Optimization
                                            </li>
                                            <li>
                                                <i class="fa-solid fa-check" />
                                                &nbsp;Advanced Website Design
                                            </li>
                                        </ul>
                                        <a style={{ textDecoration: "none" }} href="/contact">
                                            <button type="button" class="btn btn-lg btn-block btn-outline-primary">Contact Us</button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container >
        </>
    );
}
import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { Container, CssBaseline } from "@mui/material";
import { Helmet } from "react-helmet-async";

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function Faq() {
    const [expanded, setExpanded] = React.useState('panel1');

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    return (
        <>
            <Helmet>
                <title>Edward's Web Solutions FAQs</title>
                <meta name="description" content="Edward\'s Web Solutions is here to help you take your business to the next level. 
                    We specialize in creating websites for businesses to increase the amount of customers you get." />
                <link rel="canonical" href="/faq" />
            </Helmet>
            <Container style={{ textAlign: "center", minHeight: "91vh", display: 'flex', flexDirection: "column", marginTop: 120 }}>
                <CssBaseline />
                <br />
                <h2 style={{ textAlign: "center" }}>Frequently Asked Questions</h2>
                <br />
                {/*1*/}
                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                    <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                        <Typography style={{ textAlign: "left" }}>
                            What is the process involved in creating your website?
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography style={{ textAlign: "left" }}>
                            Answer: Our website creation process typically involves several steps, starting with understanding your business goals and target audience. We then proceed with designing mockups, developing the website, testing it for functionality and responsiveness, and finally launching it after your approval.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                {/*2*/}
                <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                    <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                        <Typography style={{ textAlign: "left" }}>
                            How long does it take to create your website?
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography style={{ textAlign: "left" }}>
                            Answer: The timeline for website development varies based on the complexity and size of the project. A simple website can take a few weeks, while more complex ones may require a few months. We work closely with our clients to establish realistic deadlines and provide regular updates throughout the process.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                {/*3*/}
                <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                    <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                        <Typography style={{ textAlign: "left" }}>
                            Do you offer custom website designs or use templates?
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography style={{ textAlign: "left" }}>
                            Answer: We specialize in custom website designs tailored to each client's unique requirements. While we don't rely on pre-made templates, we can certainly incorporate your preferred design elements or branding into the website.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                {/*5*/}
                <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                    <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                        <Typography style={{ textAlign: "left" }}>
                            Will my website be mobile-friendly and optimized for search engines?
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography style={{ textAlign: "left" }}>
                            Answer: Absolutely! We prioritize mobile responsiveness and ensure that your website looks great and functions seamlessly across different devices. Additionally, we employ industry best practices to optimize your website for search engines, helping improve its visibility and organic rankings.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                {/*6*/}
                <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                    <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                        <Typography style={{ textAlign: "left" }}>
                            Can I make updates to the website myself after it's launched?
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography style={{ textAlign: "left" }}>
                            Answer: As part of our website development process using React, we provide ongoing support and maintenance services. If you need to make updates or modifications to your website, you can contact me directly, and I will take care of it for you promptly. I will ensure that any changes are implemented accurately and professionally, maintaining the integrity and functionality of your website. Rest assured, I am dedicated to providing you with reliable and timely support to keep your website up-to-date and optimized for your business needs.                        </Typography>
                    </AccordionDetails>
                </Accordion>
                {/*7*/}
                <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
                    <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                        <Typography style={{ textAlign: "left" }}>
                            What about website hosting and domain registration?
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography style={{ textAlign: "left" }}>
                            Answer: Absolutely! As part of our comprehensive web design services, we already provide website hosting and domain registration for our clients. We offer reliable hosting options tailored to your website's requirements, ensuring that your website is accessible to visitors around the clock. Additionally, we can assist you in securing and registering a domain name that aligns perfectly with your brand. You can rely on us to handle the technical aspects of hosting and domain management, so you can focus on your business and online presence.                        </Typography>
                    </AccordionDetails>
                </Accordion>
                {/*8*/}
                <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
                    <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                        <Typography style={{ textAlign: "left" }}>
                            Do you provide ongoing website maintenance and support?
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography style={{ textAlign: "left" }}>
                            Answer: Yes, we offer ongoing maintenance and support services to ensure your website remains up-to-date, secure, and functioning optimally. Our packages include regular backups, security updates, performance monitoring, and technical support to address any issues that may arise.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            </Container>
            <br />
            <br />
        </>
    );
}
import React, { useState } from 'react';
import { TextField, Container, Stack, Alert } from '@mui/material';
import { Link } from "react-router-dom"
import emailjs from '@emailjs/browser';
import { Helmet } from "react-helmet-async";

const Contact = () => {
    const [sentMessageSuccess, setSentMessageSuccess] = useState(false)
    const [sentMessageFail, setSentMessageFail] = useState(false)
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [message, setMessage] = useState('')
    const [number, setNumber] = useState('')

    function handleSubmit(event) {
        event.preventDefault();
        console.log(firstName, lastName, email, message, number)
        var templateParams = {
            firstName: firstName,
            lastName: lastName,
            email: email,
            number: number,
            message: message,
        };

        emailjs.send('service_cgnfey6', 'template_4qdtmpr', templateParams, "DRrKRkKLKRUWU_GCw")
            .then(function (response) {
                setSentMessageSuccess(true);
            }, function (error) {
                setSentMessageFail(true);
            });
        setSentMessageSuccess(true);
        setFirstName("");
        setLastName("");
        setEmail("");
        setMessage("");
        setNumber("");
    }

    return (
        <>
            <Helmet>
                <title>Edward's Web Solutions Contact Us</title>
                <meta name="description" content="Edward\'s Web Solutions is here to help you take your business to the next level. 
                    We specialize in creating websites for businesses to increase the amount of customers you get." />
                <link rel="canonical" href="/contact" />
            </Helmet>
            <Container style={{ minHeight: "91vh", display: 'flex', flexDirection: "column", marginTop: 120 }}>
                <br />
                <React.Fragment>
                    <h2 style={{ textAlign: "center" }}>Contact Form</h2>
                    <br />
                    {sentMessageSuccess &&
                        <>
                            <Alert severity="success">Message has been sent</Alert>
                            <br />
                        </>
                    }
                    {sentMessageFail &&
                        <>
                            <Alert severity="error">Something went wrong. Try again in 5 min</Alert>
                            <br />
                        </>
                    }
                    <form onSubmit={handleSubmit} action={<Link to="/login" />}>
                        <Stack spacing={2} direction="row" sx={{ marginBottom: 4 }}>
                            <TextField
                                type="text"
                                variant='outlined'
                                color='secondary'
                                label="First Name"
                                onChange={e => setFirstName(e.target.value)}
                                value={firstName}
                                fullWidth
                                required
                            />
                            <TextField
                                type="text"
                                variant='outlined'
                                color='secondary'
                                label="Last Name"
                                onChange={e => setLastName(e.target.value)}
                                value={lastName}
                                fullWidth
                                required
                            />
                        </Stack>
                        <TextField
                            type="email"
                            variant='outlined'
                            color='secondary'
                            label="Email"
                            onChange={e => setEmail(e.target.value)}
                            value={email}
                            fullWidth
                            required
                            sx={{ mb: 4 }}
                        />
                        <TextField
                            type="phone"
                            variant='outlined'
                            color='secondary'
                            label="Phone Number"
                            onChange={e => setNumber(e.target.value)}
                            value={number}
                            required
                            fullWidth
                            sx={{ mb: 4 }}
                        />
                        <TextField
                            type="message"
                            variant='outlined'
                            color='secondary'
                            label="Message"
                            onChange={e => setMessage(e.target.value)}
                            value={message}
                            fullWidth
                            required
                            sx={{ mb: 4 }}
                        />
                        <div style={{ alignItems: "center", justifyContent: "center", textAlign: "center" }}>
                            <button variant="outlined" class="btn btn-outline-primary btn-lg" type="submit">Send Message</button>
                        </div>
                    </form>
                </React.Fragment>
            </Container>
        </>
    )
}

export default Contact;